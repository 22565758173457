import * as scmpAccount from "@product/scmp-account-sdk";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect } from "react";

import { config } from "shared/data";
import { accountAtom } from "shared/lib/account";

import { rosettaAtom } from "scmp-app/lib/rosetta";

import DefaultAnonymousIcon from "./icon-default-anonymous.svg?url";
import { Container } from "./styles";

export type Props = {
  className?: string;
  id?: string;
  onClick?: () => void;
};

export const Avatar: FunctionComponent<Props> = ({ className, id, onClick }) => {
  const { user } = useAtomValue(accountAtom);
  const asyncRosettaState = useAtomValue(rosettaAtom);

  useEffect(() => {
    const computeUserPic = () => {
      if (!user) return DefaultAnonymousIcon;
      return user.upic ? `${config.account.userPicPrefix}${user.upic}` : "";
    };

    scmpAccount.initAvatarWidget(`avatarContainer-${id}`, {
      ...user,
      subscriberState: asyncRosettaState?.result?.isScmpSubscriber,
      userPic: computeUserPic(),
    });
  }, [asyncRosettaState?.result?.isScmpSubscriber, user, id]);

  return (
    <Container
      $isLoggedIn={!!user}
      className={className}
      id={`avatarContainer-${id}`}
      onClick={onClick}
    />
  );
};
